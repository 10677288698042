import React from 'react'
import PresetItem from '../PresetItem/PresetItem'
import './style.css'

interface PresetItemInfo {
    name: string
    desc: string
    labelName: string
    changeBy: number
}

export type PresetItemsList = PresetItemInfo[]


interface PresetsModalProps {
    presets: PresetItemsList
    updateCountFunc: (countName: string, changeBy: number) => any
    onClose: () => any
    setPresetsInLocalStorage: (presetArray: PresetItemsList) => any
}

const PresetsModal: React.FC<PresetsModalProps> = ({ presets,updateCountFunc, onClose,setPresetsInLocalStorage }) => {
    async function createNewPreset(){
        let name = await prompt('Enter Preset Name: ')
        if (!name) return
        let desc = await prompt('Enter Description: ')
        if (!desc) return
        let labelName = await prompt('Enter Label Name to add to: ')
        if (!labelName) return
        let amountString = await prompt('Enter amount to increase by (can be a negative number): ')
        if (!amountString) return
        let changeBy = parseInt(amountString)

        presets.push({name,desc,labelName,changeBy})
        setPresetsInLocalStorage(presets)
    }

    function deleteItem(name: string){
        let item = presets.find((item) => {
            return item.name === name
        })

        let idx = presets.indexOf(item as undefined as any)
        presets.splice(idx,1)
        setPresetsInLocalStorage(presets)
    }

    function draw(){
        return presets.map((preset) => {
            

            return <PresetItem name={preset.name} description={preset.desc} onClick={() => updateCountFunc(preset.labelName,preset.changeBy)} onDelete={() => {deleteItem(preset.name)}}/>
        })   
    }


    return (
        <div className='presetcontainer'>
            <div className='presetHeader'>
                <h1>Presets</h1>
                <p onClick={() => onClose()}>Close</p>
            </div>
            <div className='presetitemlist'>
                {draw()}
            </div>
            <button className='createNewPreset' onClick={() => createNewPreset()}>Create new Preset</button>
        </div>);
}

export default PresetsModal