import React from 'react'
import './style.css'

type CSSColorString = string

interface CounterProps {
    count: number
    onChangeCount: (changeBy: number) => any
    label: string
    labelColor: CSSColorString
}

const Counter: React.FC<CounterProps> = ({ count,label,labelColor,onChangeCount }) => {
    return (
        <div className='container'>
            
            <div className='countContainer' style={{backgroundColor: labelColor}}>
            <button onClick={() => onChangeCount(-1)}>-</button>
            <div className='mainCountContainer'>
                <p className='label'>{label}</p>
                <p className='count'>{count}</p>
            </div>
           
            <button onClick={() => onChangeCount(1)}>+</button>
            
            </div>
        </div>
    );
}

export default Counter