import logo from './logo.svg';
import './App.css';
import Counter from './components/Counter/Counter';
import { useState } from 'react';
import PresetsModal, { PresetItemsList } from './components/PresetsModal/PresetsModal';


const presetSeperator = '-SEP(13213jljad)-'

function App() {
  let [,update] = useState('')
  let [isPresetsOpen, setIsPresetsOpen] = useState(false)

  function getCountFromLocalStorage(countName: string){
    let count = window.localStorage.getItem(`count:${countName}`)
    if (!count) count = '0'
    return parseInt(count)

  }

  function setCountInLocalStorage(countName: string,newAmount: number){
    window.localStorage.setItem(`count:${countName}`,newAmount.toString())
  }

  function getPresetArrayFromLocalStorage(){
    let string = window.localStorage.getItem('presets')
    if (!string) return []
    let stringArray = string.split(',')
    return stringArray.map((str) => {
        let [name,desc,labelName,changeByString] = str.split(presetSeperator)
        let changeBy = parseInt(changeByString)
        if (Number.isNaN(changeBy)) changeBy = 0
        return {name,desc,labelName,changeBy}
    })

    // Rule-SEP-Desc-Lvl-Change,
  }

  function setPresetsInLocalStorage(presetArray: PresetItemsList){
    let string = presetArray.map(({name,changeBy,desc,labelName}) => {
      return `${name}${presetSeperator}${desc}${presetSeperator}${labelName}${presetSeperator}${changeBy}`
    }).join(',')

    window.localStorage.setItem('presets', string)
    update(`${Math.random()}`)
  }

  function updateCount(countName: string, changeBy: number){
    setCountInLocalStorage(countName,getCountFromLocalStorage(countName) + changeBy)
    switch (countName){
      case 'lvl1':
          let count1 = getCountFromLocalStorage('lvl1')
          if (count1 > 3) {
            updateCount('lvl1',-4)
            updateCount('lvl2',1)
          }
          break;

      case 'lvl2':
        let count2 = getCountFromLocalStorage('lvl2')
        if (count2 > 3) {
          updateCount('lvl2',-4)
          updateCount('lvl3',1)
        }
        break;

        
    }

    update(`${Math.random()}`)
  }


  async function onClear(){
    let canClear = await window.confirm("Are you sure you'd like to clear?")
    if (canClear){
      let presetString = window.localStorage.getItem('presets')
      window.localStorage.clear(); 
      if (!!presetString) window.localStorage.setItem('presets', presetString)
      update('0')
    }
  }
  




  return (
    <div className="App">
      <div className='listContainer'>
        <Counter count={getCountFromLocalStorage('lvl1')} label='Level 1' labelColor='yellow' onChangeCount={(changeBy) => updateCount('lvl1',changeBy)}/>
        <Counter count={getCountFromLocalStorage('lvl2')} label='Level 2' labelColor='orange' onChangeCount={(changeBy) => updateCount('lvl2',changeBy)}/>
        <Counter count={getCountFromLocalStorage('lvl3')} label='Level 3' labelColor='red' onChangeCount={(changeBy) => updateCount('lvl3',changeBy)}/>
        <button className='clearBtn' onClick={() => {onClear()}}>Clear</button>
        <button className='clearBtn' onClick={() => {setIsPresetsOpen(true)}}>Presets</button>
      </div>
      {isPresetsOpen ?
      <PresetsModal presets={getPresetArrayFromLocalStorage()} updateCountFunc={updateCount} onClose={() => setIsPresetsOpen(false)} setPresetsInLocalStorage={setPresetsInLocalStorage}/>
      :null}
      </div>
  );
}

export default App;
