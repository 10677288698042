import React from 'react'
import './style.css'

interface PresetItemProps {
    name: string
    description: string
    onClick?: () => any
    onDelete?: () => any
}

const PresetItem: React.FC<PresetItemProps> = ({ name,description,onClick = () => 1, onDelete=() => 1}) => {
    return (
    <div className='presetItemContainer' onClick={() => {onClick()}}>
        <p className='presetItemTitle'> {name}</p>
        <p className='presetItemDesc'>{description}</p>
        <p className='presetItemDelete' onClick={() => onDelete()}>Delete</p>
    </div>);
}

export default PresetItem